import React from "react";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormSend: false,
      isLoading: false,
      error: null,
      botField: "",
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state),
    };
    fetch(
      "https://nieladakawa.pl/api/contact.php",
      requestOptions
    )
      .then((response) => response.json())
      .then(() =>
        this.setState({
          isFormSend: true,
          isLoading: false,
          name: "",
          email: "",
          phone: "",
          message: "",
        })
      )
      .catch((error) =>
        this.setState({ error: error.message, isLoading: false })
      );
  };
  render() {
    return (
      <div className="form-contact contact_form">
        <form name="Contact Form" method="POST" onSubmit={this.handleSubmit}>
          <div className="hidden">
            <label>
              Don’t fill this out if you're human:{" "}
              <input
                name="bot-field"
                value={this.state.botField}
                onChange={(e) => this.setState({ botField: e.target.value })}
              />
            </label>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Imię i nazwisko"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="E-mail"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Telefon"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <textarea
                  type="text"
                  rows="4"
                  name="message"
                  className="form-control"
                  placeholder="Wiadomość"
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  required
                ></textarea>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <button
                  type="submit"
                  value="Wyślij"
                  className={`button button-button-square button-primary ${
                    this.state.isLoading ? "is-loading" : ""
                  }`}
                  disabled={this.state.isLoading}
                >
                  Wyślij
                  {this.state.isLoading && (
                    <div
                      class="spinner-border text-success"
                      role="status"
                    ></div>
                  )}
                </button>
                {this.state.isFormSend && (
                  <div className="success-message">
                    Wiadomość wysłana poprawnie
                  </div>
                )}
                {this.state.error && (
                  <div className="error-message">
                    Wystąpił błąd podczas wysyłania wiadomości
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
