import React from "react";
import ContactForm from "../forms/ContactForm";
import Pin from "../../images/icons/pin.svg";
import Smartphone from "../../images/icons/smartphone.svg";
import Mail from "../../images/icons/mail.svg";

export default function ContactSection({ isHome = false }) {
  return (
    <section
      id="contact"
      className="contact-section padding-top padding-bottom-xs"
    >
      <div className="container">
        {isHome && (
          <div className="contact-header">
            <h2 className="section-title">KONTAKT</h2>
            <p className="section-description">
              Skontaktuj się z nami, nasi konsultanci nie mogą się doczekać
              nowych wyzwań.
            </p>
          </div>
        )}
        <div className="row">
          <div className="col-lg-6 col-lg-offset-1">
            <ContactForm />
          </div>
          <div className="col-lg-5">
            <div className="text-box-padding-xs-left">
              <div className="media contact-info">
                <Pin className="contact-info__icon" />
                <div className="media-body">
                  <h3>Adres</h3>
                  <p>
                    Gniewska 21 <br /> 81-047 Gdynia
                  </p>
                </div>
              </div>
              <div className="media contact-info">
                <Smartphone className="contact-info__icon" />
                <div className="media-body">
                  <h3>Telefon</h3>
                  <p>606 172 993</p>
                </div>
              </div>
              <div className="media contact-info">
                <Mail className="contact-info__icon" />
                <div className="media-body">
                  <h3>E-mail</h3>
                  <a href="mailto:biuro@nieladakawa.pl">biuro@nieladakawa.pl</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
